<template>
  <section>
    <v-container fill-height v-if="load">
      <v-row align="center" justify="center" class="mt-5">
        <v-col cols="12" xl="11">
          <div class="d-flex justify-space-between align-center mb-5">
            <div class="d-flex ">
              <!-- <v-icon small>mdi-eye</v-icon>
              <div class="caption ml-2">{{count}}</div> -->
            </div> 
            <v-btn color="red" class="white--text" @click="leave">
              Leave Meeting
            </v-btn>
          </div>
          
          <v-row :class="$vuetify.breakpoint.smAndDown ? 'mb-10 pb-10' : ''">
            <v-col cols="12" xl="9" md="8" >
              <div v-html="embed.link" style="height: 570px"></div>
              <!-- <url :url="embed.link"/> -->
            </v-col>
            <v-col cols="12" xl="3" md="4">
              <v-card outlined style="border-radius: 0px" >
                <v-card height="500" flat>
                  <v-card-title class="success white--text">
                    Chat And Q&A
                  </v-card-title>
                  <v-card-text id="chatdrawer" style="max-height: 440px;overflow: auto">
                    <v-sheet v-for="item in comm.comments" :key="item.id"
                    class="my-3">
                      <v-row no-gutters align="center" style="flex-wrap: nowrap">
                        <v-col cols="11">
                          <div class="d-flex justify-space-between align-center">
                            <div class="mx-2" style="font-size: 10px">
                              {{item.user.firstname + ' ' + item.user.lastname}}
                            </div>
                            <div class="" style="font-size: 9px">
                              {{$helpers.dateDiff(item.created_at)}}
                            </div>
                          </div>
                          <v-sheet outlined class="pa-3" rounded="lg">
                            <div class="caption" v-html="item.comment"/>
                          </v-sheet>
                        </v-col>
                        <v-col cols="1">
                          <v-avatar v-if="item.user.image" color="grey" size="32" class="ml-1">
                            <v-img :src="`${item.user.aws}/${item.user.model}/${item.user.uuid}/image/${item.user.image.image}`"/>            
                          </v-avatar>
                        </v-col>
                      </v-row>
                      
                    </v-sheet>
                  </v-card-text>
                </v-card>
                <!-- <v-divider/> -->
                <v-text-field outlined dense 
                hide-details 
                v-model="comment"
                class="ma-3" 
                label="Input message here..." 
                append-icon="mdi-send"
                @click:append="send"/>
                <v-overlay absolute :value="overlay" >
                  <v-btn small color="success" @click="onChat" >
                    On Chat
                  </v-btn>
                </v-overlay>
              </v-card> 
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    
    
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Pusher from 'pusher-js'

export default {
  data: () => ({
    comment: '',
    overlay: true,
    first: true,
    load: true,
    count: 0
  }),
  computed: {
    ...mapState('student', {
      embed: (state) => state.embed,
      comm: (state) => state.embed_comments,
    })
  },
  mounted() {
    this.scrollBottom()
    this.showEmbed(this.$route.params.uuid)
    this.loginEmbed(this.$route.params.uuid)
    this.subscribe()
  },
  methods: {
    ...mapActions('student', [
      'showEmbed',
      'commentEmbed',
      'loginEmbed',
      'showCommentsEmbed',
    ]),

    ...mapMutations('student', [
      'embedMutation',
      'embedCommentsMutation'
    ]),

    send() {
      // console.log(this.comment);
      this.commentEmbed({
        comment: this.comment,
        id: this.embed.id
      }).then(() => {
        this.first = true

        this.comment =''
        this.scrollBottom()
      })
    },

    onChat() {
      this.showCommentsEmbed(this.embed.id)
      .then(() => {
        this.scrollBottom()
        this.overlay = false
      })
    },

    scrollBottom() {
      this.container = this.$el.querySelector("#chatdrawer");
      if(this.first) {
        setTimeout(() => {
          this.container.scrollTop = this.container.scrollHeight;
        }, 500);

        this.first = false
        return
      }

      this.container.scrollTop = this.container.scrollHeight;
    
    },
    leave() {
      if(confirm('You want to leave in this meeting?')) {
        this.$router.push({name: 'Student Meetings'})
      }
    },

    subscribe () {
      let pusher = new Pusher('b16ba45013da47f593a8', { 
        cluster: 'ap1',
        encrypted: false
      })
      console.log(pusher);
      //Chat Channel
      let chat = pusher.subscribe(`1-chat-meeting-channel`)
      chat.bind('App\\Events\\ChatMeeting', data => {
        console.log(JSON.stringify(data));
        // const res = JSON.stringify(data)
        this.onChat()
      })


      let stop = pusher.subscribe(`1-stop-meeting-channel`)
      stop.bind('App\\Events\\StopMeeting', data => {
        console.log(JSON.stringify(data));
        // const res = JSON.stringify(data)
        this.load = false
        alert('Meeting Ended')
        this.$router.push({name: 'Student Meetings'})
      })

      let join = pusher.subscribe(`1-join-meeting-channel`)
      join.bind('App\\Events\\JoinMeeting', data => {
        console.log(JSON.stringify(data));
        // const res = JSON.stringify(data)
        // this.count = data
      })

      

    }

  }
}
</script>